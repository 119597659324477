import { union } from 'lodash-es'

export const findSamplesAndTags = ({
  selectedOption,
  answers,
  questions,
}) => {
  const samples = {
    sampleIDs: [],
    items: [],
  }
  const tags = {
    tagIDs: [],
    items: [],
  }
  const assessments = {
    sampleIDs: [],
    items: [],
  }

  // Look in type
  // - Samples
  if (selectedOption?.sampleIDs?.length) {
    samples.sampleIDs = union(samples.sampleIDs, selectedOption.sampleIDs)
    samples.items.push({
      optionID: selectedOption.id,
      sampleIDs: selectedOption.sampleIDs,
    })
  }

  // Look in main questions
  let lastQuestion = null
  Object.keys(answers).forEach((questionID, index) => {
    const question = questions.find((x) => x.id === questionID)

    // Get tags when the answer was no and question was presented to user
    if (answers[questionID] === 0) {
      // Check if question was presented to user
      if (lastQuestion && answers[lastQuestion.id] === 0 && !question.alwaysShow) {
        return
      }

      // Get after tags for year questions
      if (question?.tagIDs?.length) {
        question.tagIDs.forEach((tagID) => {
          if (tagID.includes('TAG-YEAR-')) {
            const afterTag = tagID.replace('TAG-YEAR-', 'TAG-AFTER-YEAR-')
            tags.tagIDs.push(afterTag)
            tags.items.push({
              questionID,
              answer: answers[questionID],
              tagIDs: [afterTag],
            })
          }
        })
      }
      if (index === 0) {
        lastQuestion = question
      }
      return
    }

    // - Samples
    if (question?.sampleIDs?.length) {
      samples.items.push({
        questionID,
        answer: answers[questionID],
        sampleIDs: question.sampleIDs,
      })

      samples.sampleIDs = union(samples.sampleIDs, question.sampleIDs)
    }

    // - Assessments
    if (question?.assessmentIDs?.length) {
      assessments.items.push({
        questionID,
        answer: answers[questionID],
        assessmentIDs: question.assessmentIDs,
      })

      assessments.sampleIDs = union(assessments.sampleIDs, question.assessmentIDs)
    }

    // - Tags
    if (question?.tagIDs?.length) {
      tags.items.push({
        questionID,
        answer: answers[questionID],
        tagIDs: question.tagIDs,
      })

      tags.tagIDs = union(tags.tagIDs, question.tagIDs)
    }

    lastQuestion = question
  })

  // Special rule: Remove CP when PCB is present
  if (samples.sampleIDs.indexOf('ST-4') > -1) {
    const index = samples.sampleIDs.indexOf('ST-5')
    if (index > -1) {
      samples.sampleIDs.splice(index, 1)
    }
  }

  return {
    samples,
    tags,
    assessments,
  }
}
